import ApiService from "../services/api.service";
import axios from "../utils/axios";
import { baseUrl } from "../utils/strings";
import React, { PropTypes } from "react";
import { Route, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { showNotif } from "../features/notifTrialSlice";
import { useEffect } from "react";

const ProtectedRoute = ({ redirectPath = "/login", children }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user?.token;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  moment.locale("id");

  const checkIsTokenValid = async () => {
    if (token == undefined || token == "" || token == null) {
      return navigate(redirectPath, { replace: true });
    }

    if (user?.level != "Staff") {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${user.token}`;
      // dikasih product id boss
      axios.get(process.env.REACT_APP_BACKEND_DOMAIN + "/api/get-my-data")
        .then((response) => {
          let data = response.data.data;
        })
        .catch((error) => {
            return navigate(redirectPath, { replace: true });
        });
    }
  };


  useEffect(() => {
    checkIsTokenValid()
  }, [])

  // cek jika lisence trial lebih dari 30 hari
  if (user?.level !== "Superadmin" && user?.lisence === "Trial") {
    let endDate = moment(user?.tanggal);
    let startDate = moment();

    let diff = moment.duration(endDate.diff(startDate)).asDays();
    let rentangHari = Math.abs(Math.round(diff));
    if (rentangHari > 30 + user?.tambahanMasaTrial) {
      // dispatch(showNotif())
      return <Navigate to={redirectPath} replace />;
    }
  }

  return children;
};

export default ProtectedRoute;
